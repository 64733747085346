import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.div`
  min-height: 500px;
  background: #fbfbfb;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  align-items: center;
  padding-bottom: 100px;
`

const Wrapper = styled.div`
  text-align: left;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Wrapper data-aos="fade-up">
        <h1>Pagina non trovata</h1>
        <p>La pagina non è stata trovata </p>
        <Link style={{ textDecoration: "none", marginTop: -10 }} to={"/"}>
          <FontAwesomeIcon
            style={{ fontSize: 14, fontWeight: 100 }}
            icon={faArrowLeft}
          />
          <span style={{ fontSize: 16, fontWeight: 400, marginLeft: 10 }}>
            Indietro
          </span>
        </Link>
      </Wrapper>
    </Container>
  </Layout>
)

export default NotFoundPage
